import { COMMON_ENUM } from "shared/constants";
import { useCustomQuery, useMutateData, useMutateDownload } from "shared/services/base.service";
import { waitAndDownload } from "shared/services/common-dowload/download-helper-service";
import { saveAs } from "utils/download-file";

const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const ShipmentApiService = () => ({
  //GET
  useShipmentExporterQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/exporter/TMATH/TMATH`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentImporterQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/importer`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentRPackageQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/r-package/owner`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/status/${COMMON_ENUM.C_TMATH_EM_COMPANY}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //POST SEARCH
  useShipmentSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        // url: `${BaseServicePath}/shipment-management/v2/search`,
        url: `${BaseServicePath}/shipment-management/search`,
        method: "POST",
        data: body,
      });
    };
  },
  //POST DOWNLOAD EXCEL TEMPLATE
  useShipmentDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${process.env.REACT_APP_API_INVENTORY}/generate-report`,
          method: "POST",
          data: body,
          // responseType: "arraybuffer",
        },
        // {
        //   onSuccess: response => {
        //     // waitAndDownload(60, response);
        //     saveAs(response);
        //     // const blob = new Blob([data], {
        //     //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //     // });
        //     // (blob, `${fileName}`);
        //   },
        // }
      );
    };
  },
});

export const {
  useShipmentExporterQuery,
  useShipmentImporterQuery,
  useShipmentRPackageQuery,
  useShipmentStatusQuery,
  useShipmentSearchMutation,
  useShipmentDownloadExcelMutation,
} = ShipmentApiService();
