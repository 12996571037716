import React, { Fragment, useState, useMemo, useEffect } from "react";
// Utils
import { responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// Hooks
import useColumn from "./hooks/useColumn";
// Component
import { Box, Grid } from "@mui/material";
import { UploadBar, TableBar, FilterBar } from "./components";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
//Constants
import { MessageType, ModeAction } from "state/enum";
import { API_REPROCESS } from "shared/constants";

import { DEFAULT_REPROCESS_DOWNLOAD, DEFAULT_REPROCESS_UPLOAD } from "./constants/reprocess.constant";
// SERVICE
import {
  useReprocessBatchRoundQuery,
  useReprocessBusinessDataCodeQuery,
  useReprocessPlantQuery,
  useReprocessSearchMutation,
} from "shared/services/common/monitoring";
export default function ReprocessErrorScreen() {
  const functionId = "WDN98150";
  
  // Form
  const [formDownload, setFormDownload] = useState(DEFAULT_REPROCESS_DOWNLOAD);
  const [formUpload, setFormUpload] = useState(DEFAULT_REPROCESS_UPLOAD);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // Table
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // API
  const searchReprocess = useReprocessSearchMutation();
  const { data: businessDataList } = useReprocessBusinessDataCodeQuery();
  const { data: plantList } = useReprocessPlantQuery(formDownload?.businessName);
  const { data: batchRoundList } = useReprocessBatchRoundQuery(formDownload?.businessName);
  const columns = useMemo(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useColumn(rows),
    [rows]
  );
  useEffect(() => {
    document.getElementById("select-upload-business-data").focus();
  }, []);
  // State handle user is admin ?
  const [isAdmin, setIsAdmin] = useState(true);
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_REPROCESS.BUSINESS_NAME,
            type: MessageType.EMPTY,
            key: ["Business Data"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await searchReprocess(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_REPROCESS.ROW_NUMBER]: item?.rowNumber,
          [API_REPROCESS.DATA_OWNER]: item?.dataOwner,
          [API_REPROCESS.UPLOAD_SOURCE]: item?.uploadSource,
          [API_REPROCESS.RECORD_ID]: item?.recordId,
          [API_REPROCESS.PLAN_SERVER]: item?.plantServer,
          [API_REPROCESS.SCAN_TRANS_HID]: item?.scanTransHId,
          [API_REPROCESS.HH_CD]: item?.hhCd,
          [API_REPROCESS.HH_LOCATION_ID]: item?.hhLocationId,
          [API_REPROCESS.HH_LOCATION_DESC]: item?.hhLocationDesc,
          [API_REPROCESS.SCAN_MODE]: item?.scanMode,
          [API_REPROCESS.SCAN_SUB_MODE]: item?.scanSubMode,
          [API_REPROCESS.MANUAL_INPUT_FLAG]: item?.manualInputFlag,
          [API_REPROCESS.REF_LOCATION_ID]: item?.refLocationId,
          [API_REPROCESS.REF_LOCATION_DESC]: item?.refLocationDesc,
          [API_REPROCESS.DLV_VHC_ID]: item?.dlvVhcId,
          [API_REPROCESS.DLV_INSTR_SHT_NO]: item?.dlvInstrShtNo,
          [API_REPROCESS.SHIPPING_INFO_H_ID]: item?.shippingInfoHId,
          [API_REPROCESS.TOTAL_QTY]: item?.totalQty,
          [API_REPROCESS.DISCREPANCY_QTY]: item?.discrepancyQty,
          [API_REPROCESS.STATUS]: item?.sts,
          [API_REPROCESS.VANNING_STATUS]: item?.vanningSts,
          [API_REPROCESS.SEND_FLAG]: item?.sendFlag,
          [API_REPROCESS.H_CREATE_DATE]: item?.hCreateDt,
          [API_REPROCESS.H_CREATE_BY]: item?.hCreateBy,
          [API_REPROCESS.H_UPDATE_DATE]: item?.hUpdateDt,
          [API_REPROCESS.H_UPDATE_BY]: item?.hUpdateBy,
          [API_REPROCESS.SCAN_TRANS_DESTINATION_ID]: item?.scanTransDId,
          [API_REPROCESS.R_PACKAGE_TYPE_ID]: item?.rPkgTypeId,
          [API_REPROCESS.R_PACKAGE_TYPE]: item?.rPkgType,
          [API_REPROCESS.R_PACKAGE_TYPE_COMPANY_ABBR]: item?.rPkgOwnerCompAbbr,
          [API_REPROCESS.R_PACKAGE_STATUS_FROM]: item?.rPkgStsFrom,
          [API_REPROCESS.R_PACKAGE_STATUS_TO]: item?.rPkgStsTo,
          [API_REPROCESS.BARCODE_ID]: item?.barcodeId,
          [API_REPROCESS.PACK_STATUS_FROM]: item?.packStsFrom,
          [API_REPROCESS.PACK_STATUS_TO]: item?.packStsTo,
          [API_REPROCESS.SCAN_TIME_STAMP]: item?.scanTimestamp,
          [API_REPROCESS.DESTINATION_UPDATE_DATE]: item?.dUpdateDt,
          [API_REPROCESS.DESTINATION_UPDATE_BY]: item?.dUpdateBy,
          [API_REPROCESS.SCAN_QTY]: item?.scanQty,
          [API_REPROCESS.PLANT]: item?.plant,
          [API_REPROCESS.BUSINESS_NAME]: item?.businessName,
          [API_REPROCESS.PROCESS_DATE]: item?.processDt,
          [API_REPROCESS.BATCH_ROUND]: item?.batchRound,
          [API_REPROCESS.PARKING_FLAG]: item?.parkingFlag,
          [API_REPROCESS.REPROCESS_FLAG]: item?.reprocessFlag,
        })) ?? [];

      return { searchData, data };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  const refresh = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_REPROCESS.BUSINESS_NAME,
            type: MessageType.EMPTY,
            key: ["Business Data"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await searchReprocess(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_REPROCESS.ROW_NUMBER]: item?.rowNumber,
          [API_REPROCESS.DATA_OWNER]: item?.dataOwner,
          [API_REPROCESS.UPLOAD_SOURCE]: item?.uploadSource,
          [API_REPROCESS.RECORD_ID]: item?.recordId,
          [API_REPROCESS.PLAN_SERVER]: item?.plantServer,
          [API_REPROCESS.SCAN_TRANS_HID]: item?.scanTransHId,
          [API_REPROCESS.HH_CD]: item?.hhCd,
          [API_REPROCESS.HH_LOCATION_ID]: item?.hhLocationId,
          [API_REPROCESS.HH_LOCATION_DESC]: item?.hhLocationDesc,
          [API_REPROCESS.SCAN_MODE]: item?.scanMode,
          [API_REPROCESS.SCAN_SUB_MODE]: item?.scanSubMode,
          [API_REPROCESS.MANUAL_INPUT_FLAG]: item?.manualInputFlag,
          [API_REPROCESS.REF_LOCATION_ID]: item?.refLocationId,
          [API_REPROCESS.REF_LOCATION_DESC]: item?.refLocationDesc,
          [API_REPROCESS.DLV_VHC_ID]: item?.dlvVhcId,
          [API_REPROCESS.DLV_INSTR_SHT_NO]: item?.dlvInstrShtNo,
          [API_REPROCESS.SHIPPING_INFO_H_ID]: item?.shippingInfoHId,
          [API_REPROCESS.TOTAL_QTY]: item?.totalQty,
          [API_REPROCESS.DISCREPANCY_QTY]: item?.discrepancyQty,
          [API_REPROCESS.STATUS]: item?.sts,
          [API_REPROCESS.VANNING_STATUS]: item?.vanningSts,
          [API_REPROCESS.SEND_FLAG]: item?.sendFlag,
          [API_REPROCESS.H_CREATE_DATE]: item?.hCreateDt,
          [API_REPROCESS.H_CREATE_BY]: item?.hCreateBy,
          [API_REPROCESS.H_UPDATE_DATE]: item?.hUpdateDt,
          [API_REPROCESS.H_UPDATE_BY]: item?.hUpdateBy,
          [API_REPROCESS.SCAN_TRANS_DESTINATION_ID]: item?.scanTransDId,
          [API_REPROCESS.R_PACKAGE_TYPE_ID]: item?.rPkgTypeId,
          [API_REPROCESS.R_PACKAGE_TYPE]: item?.rPkgType,
          [API_REPROCESS.R_PACKAGE_TYPE_COMPANY_ABBR]: item?.rPkgOwnerCompAbbr,
          [API_REPROCESS.R_PACKAGE_STATUS_FROM]: item?.rPkgStsFrom,
          [API_REPROCESS.R_PACKAGE_STATUS_TO]: item?.rPkgStsTo,
          [API_REPROCESS.BARCODE_ID]: item?.barcodeId,
          [API_REPROCESS.PACK_STATUS_FROM]: item?.packStsFrom,
          [API_REPROCESS.PACK_STATUS_TO]: item?.packStsTo,
          [API_REPROCESS.SCAN_TIME_STAMP]: item?.scanTimestamp,
          [API_REPROCESS.DESTINATION_UPDATE_DATE]: item?.dUpdateDt,
          [API_REPROCESS.DESTINATION_UPDATE_BY]: item?.dUpdateBy,
          [API_REPROCESS.SCAN_QTY]: item?.scanQty,
          [API_REPROCESS.PLANT]: item?.plant,
          [API_REPROCESS.BUSINESS_NAME]: item?.businessName,
          [API_REPROCESS.PROCESS_DATE]: item?.processDt,
          [API_REPROCESS.BATCH_ROUND]: item?.batchRound,
          [API_REPROCESS.PARKING_FLAG]: item?.parkingFlag,
          [API_REPROCESS.REPROCESS_FLAG]: item?.reprocessFlag,
        })) ?? [];

      if (!data.length) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setPagination({});
        setPageNumber(1);
        setRows([]);
        return false;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{ padding: "1rem 1rem 0rem 1rem" }}>
        <HeaderContentBar title="WDN98150 : Reprocess Error Screen" isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          {isAdmin && (
            <UploadBar
              form={formUpload}
              setForm={setFormUpload}
              setFormDownload={setFormDownload}
              businessDataList={businessDataList}
              setMsg={{ setMsgAlert, setMsgError }}
              setMode={setMode}
              setOnSearch={setOnSearch}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={functionId}
            />
          )}
          <FilterBar
            mode={mode}
            setMode={setMode}
            form={formDownload}
            setForm={setFormDownload}
            setFormUpload={setFormUpload}
            rows={rows}
            isAdmin={isAdmin}
            setRows={setRows}
            setOnSearch={setOnSearch}
            refresh={refresh}
            table={{
              rowModesModel,
              rowSelectionModel,
              setRowModesModel,
              setRowSelectionModel,
            }}
            pagination={{
              setPagination,
              setPageNumber,
              pagination,
              pageNumber,
            }}
            setMsg={{ setMsgAlert, setMsgError }}
            dataList={{
              businessDataList: businessDataList,
              plantList: plantList,
              batchRoundList: batchRoundList,
            }}
            functionId={functionId}
          />
        </Grid>
        {onSearch && (
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TableBar
              form={formDownload}
              mode={{ mode, setMode }}
              rows={rows}
              columns={columns}
              getSearch={getSearch}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              setMsg={{ setMsgAlert, setMsgError }}
              pagination={{
                setPagination,
                setPageNumber,
                pagination,
                pageNumber,
              }}
            />
          </Grid>
        )}
      </Box>
    </Fragment>
  );
}
