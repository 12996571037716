import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
//Util
import { isBlankOrNull, responseErrors } from "utils";
//Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Service
import {
  useContainerGetActualRPkgMutation,
  useRPackageGroupCodeGetMutation,
  useShippingUpdateTempContainerMutation,
} from "shared/services/invoice";
//Type
import { ModeAction } from "state/enum";
import { COMMON_ENUM } from "shared/constants";
export default function ConfirmMatching({
  form,
  setForm,
  rows,
  setRows,
  modeOfWDN95030,
  mode,
  getSumQty,
  rPkgQty,
  setRPkgQty,
  setOnOpen,
  updateTempContainer,
  loading: { startLoading, stopLoading },
  setMsg: { setMsgAlert, setMsgError },
  rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  functionId,
}) {
  const confirmDialogCtx = useConfirmDialogContext();
  // Api
  const getGroupCdRPkg = useRPackageGroupCodeGetMutation();
  const actualRPkg = useContainerGetActualRPkgMutation();
  const updateTempContd = useShippingUpdateTempContainerMutation();

  const sumQty = async row => {
    try {
      let sumRMQty = 0;
      let sumOTQty = 0;

      const actualRPkgList = await actualRPkg({
        // vanningInfoHId: "VNH_2309_012993",
        // planContainerNo: "EITU9258849",
        vanningInfoHId: row?.vanningInfoHId,
        planContainerNo: row?.actualContainerNo,
      });
      if (!actualRPkgList?.result?.length) {
        sumRMQty = 0;
        sumOTQty = 0;
      } else {
        console.log("has data actualRPkgList");
        actualRPkgList?.result?.forEach(async rPkg => {
          console.log(rPkg);
          if (rPkg?.groupCd === COMMON_ENUM.C_R_MODULE) {
            sumRMQty += parseInt(rPkg?.qty);
          } else {
            sumOTQty += parseInt(rPkg?.qty);
          }
        });
      }

      setRPkgQty({ sumRMQty, sumOTQty });

      return { sumRMQty, sumOTQty };
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
    }
  };

  const handleConfirmMatch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({ type: "WDN95031_CONFIRM_MATCHING" });
      if (!confirm) {
        return;
      }

      //NOTE - In case no selected record will close container sub screen
      if (!rowSelectionModel?.length) {
        // let tempRows;
        let rowModesModal;

        await form?.rows?.forEach(async (row, index) => {
          const { actualSumRMQty, actualSumOTQty } = await sumQty(row);
          // new Promise(resolve => setTimeout(resolve, 2000));
          // console.log(actualSumRMQty, actualSumOTQty);
          let notEqual = false;
          if (row === COMMON_ENUM.VANNING_STATUS_INCOMPLETE) {
            if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
            if (parseInt(row.planRModuleQty) !== actualSumRMQty) notEqual = true;
            if (parseInt(row.planRBoxDunQty) !== actualSumOTQty) notEqual = true;
            rowModesModal = {
              ...row,
              status: notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED,
            };
            return rowModesModal;
          }
          return row;
        });
        console.log("form container sub: ", form);
        // await updateTempContd();
        // setForm(prev => ({ ...prev, rows: tempRows }));
        setOnOpen(false);
        return;
      }
      // in case has selected record
      else {
        let tempUpdateRow = [...rows];
        let tempRowSelectionModel = [...rowSelectionModel];
        rowSelectionModel?.forEach(selected => {
          const rowSelected = rows.find(v => v.rowNumber === selected);

          form?.rows.forEach((item, index) => {
            // rows.forEach((item, index) => {
            if (item.rowNumber === rowSelected.rowNumber) {
              // In case record status is Complete, no need to do anything
              if (rowSelected?.status !== "N") {
                tempRowSelectionModel = tempRowSelectionModel.filter(idx => idx === rowSelected?.rowNumber);
                return;
              }
              // In case that is add new will don't have actual ContainerNo
              // change status to Complete only
              if (!rowSelected?.actualContainerNo) {
                // set rows for table
                const temp = { ...rowSelected, status: "Y" };
                tempUpdateRow[index] = { ...tempUpdateRow[index], ...temp };
              }
              // In case that is delete shipping data(plan) will don't have shipping ContainerNo
              else if (
                isBlankOrNull(rowSelected?.planContainerNo) &&
                isBlankOrNull(rowSelected.planRModuleQty) &&
                isBlankOrNull(rowSelected.planRBoxDunQty)
              ) {
                // sumQty(item);
                //TODO -  GET actual qty
                const temp = {
                  ...rowSelected,
                  planContainerNo: rowSelected?.actualContainerNo,
                  planRModuleQty: rowSelected?.actualRModuleQty,
                  planRBoxDunQty: rowSelected?.actualRBoxDunQty,
                  status: COMMON_ENUM.FLAG_YES,
                };
                tempUpdateRow[index] = { ...tempUpdateRow[index], ...temp };
              } else {
                console.log("rowSelected", rowSelected);
                if (rowSelected?.planContainerNo === rowSelected?.actualContainerNo) {
                  const temp = {
                    ...rowSelected,
                    planContainerNo: rowSelected?.actualContainerNo,
                    planRModuleQty: rowSelected?.actualRModuleQty,
                    planRBoxDunQty: rowSelected?.actualRBoxDunQty,
                    status: COMMON_ENUM.FLAG_YES,
                  };
                  tempUpdateRow[index] = { ...tempUpdateRow[index], ...temp };
                }
              }
              tempRowSelectionModel = tempRowSelectionModel.filter(idx => idx !== rowSelected?.rowNumber);
            }
          });
        });
        setRowSelectionModel(tempRowSelectionModel);
        setRows(tempUpdateRow);
        setForm(prev => ({ ...prev, rows: [...tempUpdateRow] }));
        await updateTempContainer(tempUpdateRow, form?.shippingSessionId);
        return true;
      }
      // return true;
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  return (
    <Fragment>
      {[ModeAction.VIEW, ModeAction.SELECTED].includes(modeOfWDN95030) ? (
        <div></div>
      ) : (
        ![ModeAction.ADD, ModeAction.EDIT, ModeAction.COPY].includes(mode) && (
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <InputButton
              id="button-confirm-match"
              value="Confirm Matching"
              onClick={handleConfirmMatch}
              name={`${functionId}Confirm`}
            />
          </Box>
        )
      )}
    </Fragment>
  );
}
