import dayjs from "dayjs";
import { API_WAREHOUSE_SUMMARY } from "shared/constants";

const deepClone = obj => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  const clonedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
};

const formatRowWareHouseSummary = dataRaw => {
  try {
    let data = [];
    let rPkg = [];
    if (!dataRaw?.length) {
      console.log("data not found");
      return;
    }
    dataRaw.forEach((val, index) => {
      const row = {
        no: index + 1,
        [API_WAREHOUSE_SUMMARY.ROW_NUMBER]: val.rowNumber,
        [API_WAREHOUSE_SUMMARY.SHIPMENT_CODE]: val.shipmentCd,
        [API_WAREHOUSE_SUMMARY.CONTAINER_NO]: val.containerNo,
        [API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS]: val.allocateStatus,
        [API_WAREHOUSE_SUMMARY.READY_FOR_DATE]: val.readyForDt,
        whCompanyAbbrId: val.whCompanyAbbrId,
        whCompanyAbbr: val.whCompanyAbbr,
        [API_WAREHOUSE_SUMMARY.WARE_HOUSE_NAME]: val.whImpExpCd,
        [API_WAREHOUSE_SUMMARY.PLANT_ID]: val.whPlantId,
        [API_WAREHOUSE_SUMMARY.ALLOCATE_DATE]: val.allocateDt,
      };
      val.rPkg.forEach(item => {
        row[item.key] = parseInt(item.qty);
        if (!rPkg.includes(item.key)) return rPkg.push(item.key);
      });
      data.push(row);
    });
    return { data, rPkg };
  } catch (error) {
    console.error(error);
  }
};
const formatRowUpdated = (rowSelectionModel, getWarehouse) => {
  if (!rowSelectionModel.length) return [];

  const data = rowSelectionModel?.map(item => ({
    [API_WAREHOUSE_SUMMARY.SHIPMENT_CODE]: !item?.shipmentCd ? "" : item?.shipmentCd,
    [API_WAREHOUSE_SUMMARY.CONTAINER_NO]: !item?.containerNo ? "" : item?.containerNo,
    [API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS]: !item?.allocateStatus ? "" : item?.allocateStatus,
    [API_WAREHOUSE_SUMMARY.WARE_HOUSE_NAME]: !item?.plantId ? "" : getWarehouse(item?.plantId),
    [API_WAREHOUSE_SUMMARY.PLANT_ID]: !item?.plantId ? "" : item?.plantId,
    [API_WAREHOUSE_SUMMARY.ALLOCATE_DATE]: !item?.allocateDt
      ? ""
      : dayjs(item?.allocateDt, "DD/MM/YYYY").format("YYYY-MM-DD"),
  }));

  return data;
};

const simulateGraph = (editData, graphData, rPkgs) => {
  try {
    const tempGraphData = deepClone(graphData);

    tempGraphData?.forEach(wh => {
      let tempWh = wh;
      editData.forEach(row => {
        // find warehouse

        if (row?.plantId !== tempWh?.plantId) {
          return tempWh;
        }

        // find r-package
        tempWh.rPkgTypeList.forEach((type, index) => {
          // Find r-package
          for (const rPkg of rPkgs) {
            console.log(type.rPkgType);
            console.log(rPkg);
            if (type.rPkgType !== rPkg) {
              continue;
            }
            // find forecast date rPkgs with allocated date
            let isAfterAllocateDt = false;
            type.dateQtyList.forEach(date => {
              console.log(
                "passed",
                date.forecastDt,
                dayjs(row.allocateDt, "DD/MM/YYYY").format("DD/MM"),
                date.forecastDt !== dayjs(row.allocateDt, "DD/MM/YYYY").format("DD/MM") && isAfterAllocateDt
              );
              if (date.forecastDt === dayjs(row.allocateDt, "DD/MM/YYYY").format("DD/MM") || isAfterAllocateDt) {
                isAfterAllocateDt = true;
                date.newEndingBalance = parseInt(date.newEndingBalance) + parseInt(row[rPkg]);
              }
            });
            continue;
          }
        });
      });
    });
    return tempGraphData;
  } catch (error) {
    console.error(error);
  }
};
const simulateGraphModel = (row, graphData, rPkg) => {
  try {
    const tempGraphData = graphData?.map(wh => {
      let tempWh = { ...wh };
      // find warehouse
      if (row.plantId !== tempWh.plantId) {
        return tempWh;
      }
      // find r-package
      tempWh.rPkgTypeList.forEach((type, index) => {
        if (type?.rPkgType !== rPkg[index]) {
          return;
        }
        // find forecast date match with allocated date
        type.dateQtyList.forEach(date => {
          if (date.forecastDt !== dayjs(row.allocateDt, "DD/MM/YYYY").format("DD/MM")) {
            return;
          }

          let newEndingBalance = parseInt(date.newEndingBalance) + parseInt(row[rPkg[index]]);
          date.newEndingBalance = newEndingBalance;
          // date.newEndingBalance = newEndingBalance.toString();
          return;
        });
      });
      return tempWh;
    });
    // console.log("defaultGraphData", graphData);
    return tempGraphData;
  } catch (error) {
    console.error(error);
  }
};

const transformObjectGraph = entryData => {
  const { data } = entryData;
  const graphData = [];

  if (!data) {
    console.error("Invalid input data format.");
    return [];
  }

  const warehouseList = Object.keys(data);
  if (warehouseList === null || warehouseList?.length <= 0) {
    console.error("No data graph found");
    return [];
  }

  // Check if data exist
  for (const warehouse of warehouseList) {
    const record = data[`${warehouse}`];
    const label = record?.Label;
    const plantId = record?.PlantId;
    const dataSet = record?.DataSet;
    // console.log(`${label}: `, dataSet);

    const rPkgTypeList = [];
    for (const [rPkgTypeKey, rPkgTypeValue] of Object.entries(dataSet)) {
      const dateQtyList = [];

      const dates = rPkgTypeValue.forecastDt || [];
      const mins = rPkgTypeValue.min || [];
      const maxs = rPkgTypeValue.max || [];
      const endingBalances = rPkgTypeValue.ending || [];

      // Determine the maximum length among the arrays
      const maxLength = Math.max(dates.length, mins.length, maxs.length, endingBalances.length);

      // Iterate over each date entry
      for (let i = 0; i < maxLength; i++) {
        dateQtyList.push({
          forecastDt: dates[i] || null,
          min: mins[i] || null,
          max: maxs[i] || null,
          endingBalance: endingBalances[i] || null,
          newEndingBalance: endingBalances[i] || null,
        });
      }

      rPkgTypeList.push({
        rPkgType: rPkgTypeKey,
        dateQtyList,
      });
    }
    graphData.push({
      whImpExpCd: label,
      plantId: plantId,
      rPkgTypeList,
    });
  }
  return graphData;
};

export { formatRowWareHouseSummary, formatRowUpdated, simulateGraph, simulateGraphModel, transformObjectGraph };
