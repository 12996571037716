const { API_NAME_APMC } = require("shared/constants");

export const transformDataApmc = (searchData, pageNumber, rowPerPage) => {
  try {
    // const countPage = Number(pageNumber) * Number(rowPerPage);
    // console.log("countPage :>>> ", countPage);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        no: index + 1,
        [API_NAME_APMC.ROW_NUMBER]: item.rowNumber,
        [API_NAME_APMC.ORG_FILE_NAME]: item.orgFileName,
        [API_NAME_APMC.FILE_UPLOAD_ID]: item.fileUploadId,
        [API_NAME_APMC.FILE_NAME]: item.fileName,

        [API_NAME_APMC.FORECAST_MONTH]: item.forecastMonth,
        [API_NAME_APMC.MODE]: item.mode,
        [API_NAME_APMC.DATA_TYPE]: item.source,
        [API_NAME_APMC.DATA_OWNER]: item.dataOwner,

        [API_NAME_APMC.UPLOAD_IMP_EXP_CD]: item.uploadImpExpCd,
        [API_NAME_APMC.UPLOAD_BY]: item.uploadBy,
        [API_NAME_APMC.UPLOAD_DATE]: item.uploadDt,
        [API_NAME_APMC.UPLOAD_STATUS]: item.uploadSts,

        [API_NAME_APMC.USE_STATUS]: item.useSts,
        [API_NAME_APMC.USE_DATE]: item.useDt,
        [API_NAME_APMC.USE_BY]: item.useBy,

        [API_NAME_APMC.UPDATE_DATE]: item.updateDt,

        [API_NAME_APMC.MODULE_ID]: item?.moduleId,
        [API_NAME_APMC.FUNCTION_ID]: item?.functionId,
        [API_NAME_APMC.D_HODTCRE]: item?.dHODTCRE,
        [API_NAME_APMC.API_ID]: item?.aplId,
      })) ?? [];
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
