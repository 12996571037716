import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
import { waitAndDownload } from "../common-dowload/download-helper-service";
const BaseServicePath = process.env.REACT_APP_API_HANDHELD;

const PrintDlvTypeShtApiService = () => ({
  useUPrintDlvTypeShtGetDeliveryTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-delivery-type-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  usePrintDlvTypeShtGetPackStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-pack-status-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  usePrintDlvTypeShtGetShippingInfoQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-shipping-infomation-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  usePrintDlvTypeShtGetSenderListQuery: deliveryTypeId => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-sender-list`,
      method: "POST",
      body: { deliveryType: deliveryTypeId },
      enabled: !!deliveryTypeId,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  usePrintDlvTypeShtGetReceiveListQuery: deliveryTypeId => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-receiver-list`,
      method: "POST",
      body: { deliveryType: deliveryTypeId },
      enabled: !!deliveryTypeId,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  usePrintDlvTypeShtSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async data => {
      return await mutate({
        url: `${BaseServicePath}/main-server-screen/search`,
        data: data,
        method: "POST",
      });
    };
  },
  usePrintDlvTypeShtDownloadMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/main-server-screen/download-delivery-instruction-sheet`,
          method: "POST",
          data: body,
          // responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            waitAndDownload(60, response);
            // saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
          },
        }
      );
    };
  },
});

export const {
  useUPrintDlvTypeShtGetDeliveryTypeQuery,
  usePrintDlvTypeShtGetPackStatusQuery,
  usePrintDlvTypeShtGetShippingInfoQuery,
  usePrintDlvTypeShtGetSenderListQuery,
  usePrintDlvTypeShtGetReceiveListQuery,
  usePrintDlvTypeShtSearchMutation,
  usePrintDlvTypeShtDownloadMutation,
} = PrintDlvTypeShtApiService();
