import { getLocalDate } from "utils/init-config-date";

export default function useColumnHistory() {
  return [
    {
      field: "rowNumber",
      sortable: false,
      headerName: "No",
      headerAlign: "center",
      align: "right",
      width: 50,
      // minWidth: 50,
      // flex: 0.1,
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "printDt",
      sortable: false,
      headerName: "Print Date (DD/MM/YYYY)",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: params => {
        return getLocalDate(params.value, "DD/MM/YYYY h:mm:ss").format("DD/MM/YYYY");
      },
    },
    {
      field: "barcodeIdFrom",
      sortable: false,
      headerName: "Barcode Form",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "barcodeIdTo",
      sortable: false,
      headerName: "Barcode To",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "printBy",
      headerName: "Print User",
      sortable: false,
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "left",
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "printQty",
      sortable: false,
      headerName: "Total Qty",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "right",
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "numOfCopy",
      sortable: false,
      headerName: "Number of Copy",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "right",
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "printType",
      sortable: false,
      headerName: "Print Type",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "left",
      editable: false,
      renderCell: params => {
        return params.value;
      },
    },
  ];
}
