import React from "react";
import { Box } from "@mui/material";
import {
  InputButton,
  EditableDropDown,
  EditableDatePicker,
  EditableTextField,
  InputEditTableCurrency,
} from "components/UI";
import { COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO } from "../constants/table";
import { ModeAction } from "state/enum";
import { API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO } from "shared/constants/api-name/invoice/exportInvoiceCreation";
import { findObject } from "utils";
export const useColumnCreationSubScreen = ({
  rows,
  mode,
  // setValueFieldTable,
  handleValueFieldTable,
  handleOpenContainerSubScreen,
  dataList: {},
}) => {
  return [
    // Col 1
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ROW_NUMBER,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACKING_MONTH,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACKING_MONTH,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 3
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_CODE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXPORTER_CODE,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.expCd;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 4
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_COMPANY_ABBR,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMPORTER_COMPANY,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 5
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_CODE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMPORTER_CODE,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 6
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VANNING_PLANT,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VANNING_PLANT,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.vanningPlant;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 7
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.RENBAN_NO,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.RENBAN_NO,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.renbanNo;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required />;
        }
        return params.value;
      },
    },
    // Col 8
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ORDER_TYPE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ORDER_TYPE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.orderType;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 9
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VESSEL_NAME,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VESSEL_NAME,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.vesselName;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.CONTAINER_NO,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.CONTAINER_NO,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.containerNo;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ETD_DATE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ETD_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.etdDt;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.R_PKG_TYPE,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.R_PKG_TYPE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.rPkgType;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACK_STATUS,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACK_STATUS,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.packStatus;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.QTY,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PLAN_QTY,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.qty;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    {
      field: API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.WEIGHT,
      headerName: COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.WEIGHT_PCS,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.weight;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableCurrency
              {...params}
              required
              style={{ width: "100%" }}
              maxLimit={9999}
              // maxLimit={9999.999}
              // onChange={e => setRPkgQty(prev => ({ planQty: e.value }))}
            />
          );
        } else if (mode === ModeAction.EDIT) {
          <InputEditTableCurrency
            {...params}
            value={params?.value}
            required
            style={{ width: "100%" }}
            maxLimit={9999}
            // maxLimit={9999.999}
            // onChange={e => setRPkgQty(prev => ({ planQty: e.value }))}
          />;
        }
        return params.value;
      },
    },
  ];
};
