const route = [
  {
    title: "Master",
    subItems: [
      {
        functionId: "WDN91010",
        title: "R-Package Type Master Maintenance Screen",
        path: "/master/r-package-type",
      },
      {
        functionId: "WDN91020",
        title: "R-Package Master Maintenance Screen",
        path: "/master/r-package",
      },
      {
        functionId: "WDN91030",
        title: "Route Point Master Maintenance Screen",
        path: "/master/route-point",
      },
      {
        functionId: "WDN91040",
        title: "Path Master Maintenance Screen",
        path: "/master/path",
      },
      // {
      //   title: "Logistic Route Master Maintenance Screen",
      //   path: "/master/logistic",
      // },
      {
        functionId: "WDN91060",
        title: "Volume Dimension Master Maintenance Screen",
        path: "/master/volume-dismension",
      },
      {
        functionId: "WDN91070",
        title: "Gentan-I Master Maintenance Screen",
        path: "/master/gentan-i",
      },
      {
        functionId: "WDN91020",
        title: "Safety Stock and Overflow Master Maintenance Screen",
        path: "/master/safety-stock-and-overflow",
      },
      {
        functionId: "WDN91090",
        title: "Container Group Master Maintenance Screen",
        path: "/master/container-group",
      },
      {
        functionId: "WDN910A0",
        title: "Company and Plant Master Maintenance Screen",
        path: "/master/company-plant-master",
      },
      {
        functionId: "WDN910B0",
        title: "Download and Upload Master Maintenance Screen",
        path: "/master/download-upload-master",
      },
      {
        functionId: "WDN910C0",
        title: "Barcode Print Screen",
        path: "/master/barcode-print",
        subItems: [
          {
            functionId: "WDN910C0",
            title: "Barcode Print Screen For New-print",
            path: "/master/barcode-print?mode=N",
          },
          {
            functionId: "WDN910C0",
            title: "Barcode Print Screen For Re-print",
            path: "/master/barcode-print?mode=R",
          },
          {
            functionId: "WDN910C0",
            title: "Barcode Print Screen For History",
            path: "/master/barcode-print?mode=H",
          },
        ],
      },
    ],
  },
  {
    title: "Forecast",
    subItems: [
      {
        functionId: "WDN92010",
        title: "Yearly / Monthly Data Upload Screen",
        path: "/forecast/forecast-type",
      },
      {
        functionId: "WDN92020",
        title: "APMC Forecast Screen",
        path: "/forecast/apmc-forecast",
      },
      {
        functionId: "WDN920K0",
        title: "Shipment Management Screen",
        path: "/forecast/shipment-forecast",
      },
    ],
  },
  {
    title: "Inventory",
    subItems: [
      {
        functionId: "WDN93040",
        title: "Other Material Stock Management Screen",
        path: "/inventory/other-material-stock-management",
      },
      {
        functionId: "WDN93051",
        title: "R-Package Warehouse Allocation Summary Screen",
        path: "/inventory/warehouse-allocation-summary",
      },
    ],
  },
  {
    title: "Invoice",
    subItems: [
      {
        functionId: "WDN95010",
        title: "Export Invoice Creation Screen",
        path: "/invoice/export-invoice-creation",
      },
      {
        functionId: "WDN95020",
        title: "Import Invoice Download Screen",
        path: "/invoice/import-invoice-download",
      },
      {
        functionId: "WDN95030",
        title: "Shipping Information Maintenance Screen",
        path: "/invoice/shipping-information-maintenance",
      },
    ],
  },
  {
    title: "Report",
    subItems: [
      {
        functionId: "WDN96010",
        title: "Monthly Forecast Report",
        path: "/report/monthly-forecast-report",
      },
      {
        functionId: "WDN96010",
        title: "Daily Inventory Management Report",
        path: "/report/daily-inventory-management-report",
      },
      {
        functionId: "WDN96010",
        title: "Delivery Plan Actual Report",
        path: "/report/delivery-plan-actual-report",
      },
      {
        functionId: "WDN96010",
        title: "Stock IN OUT Report",
        path: "/report/stock-in-out-report",
      },
      {
        functionId: "WDN96010",
        title: "R-Package Customs Balance Report",
        path: "/report/rpackage-customs-balance-report",
      },
      {
        functionId: "WDN96010",
        title: "Returning Punctuality Report",
        path: "/report/returning-punctuality-report",
      },
      {
        functionId: "WDN96010",
        title: "Stock Summary Report by status",
        path: "/report/stock-summary-report",
      },
      {
        functionId: "WDN96010",
        title: "Aging Stock & UsageRaw Data Report",
        path: "/report/aging-stock-usage-raw-data-report",
      },
      {
        functionId: "WDN96010",
        title: "Transaction Raw Data Report",
        path: "/report/transaction-raw-data-report",
      },
      {
        functionId: "WDN96010",
        title: "R-Package Catalog Report",
        path: "/report/rpackage-catalog-report",
      },
      {
        functionId: "WDN96010",
        title: "Stock Visualization Report",
        path: "/report/stock-visualization-report",
      },
      {
        functionId: "WDN96010",
        title: "Aging Stock Report",
        path: "/report/aging-stock-report",
      },
      {
        functionId: "WDN96010",
        title: "Average Lead Time Report",
        path: "/report/average-lead-time-report",
      },
    ],
  },

  {
    title: "Main Server",
    subItems: [
      {
        functionId: "WDN97050",
        title: "Sending Data to POLYGON Plant Server Screen",
        path: "/main-server/sending-data",
      },
      // {
      //   title: "Uploading Scanning Data from POLYGON Plant Server Screen",
      //   path: "/handheld/uploading-scanning",
      // },
      {
        functionId: "WDN97052",
        title: "Uploading R-Package Receive / Deliver / Update Status Screen",
        path: "/main-server/uploading-r-package",
      },
      {
        functionId: "WDN97053",
        title: "Print Delivery Instruction Sheet Screen",
        path: "/main-server/printing-delivery",
      },
    ],
  },
  {
    title: "Master Maintenance",
    subItems: [
      {
        functionId: "WSTD3060",
        title: "System Master (2 lvl)",
        path: "/master-maintenance/system-master",
      },
    ],
  },
  {
    title: "Common",
    subItems: [
      {
        functionId: "Monitoring",
        title: "Monitoring",
        subItems: [
          {
            functionId: "WSTD3010",
            title: "Log Monitoring Summary Screen",
            path: "/common/monitoring/log-monitoring",
          },
          {
            functionId: "WSTD3020",
            title: "On Demand Excel Download Monitoring",
            path: "/common/monitoring/on-demand-excel-download-monitor",
          },
          {
            functionId: "WSTD0090",
            title: "Excel Download",
            path: "/common/monitoring/excel-download",
          },
          {
            functionId: "WDN98150",
            title: "Reprocess Error",
            path: "/common/monitoring/reprocess-error",
          },
          {
            functionId: "WDN98170",
            title: "On Demand Batch Screen",
            path: "/common/monitoring/on-demand-batch",
          },
        ],
      },
    ],
  },
];
export default route;
