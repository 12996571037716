export const userProfile = {
  userId: "9ed18e70-53bb-4717-a34b-d92ce82afc3e",
  displayName: "Surasith Thatdee",
  givenName: "Surasith",
  surname: "Thatdee",
  id: "9ed18e70-53bb-4717-a34b-d92ce82afc3e",
  dataOwner: "TMATH",
  comAbbr: "TMATH",
  comAbbrApmc: "TMV",
  packageOwner: "TMATH",
  rPkgOwnerCompAbbr: "TMATH",
  mail: "admin@gmail.com",
  roleId: "DN9TLPXXXX.tmap-em.toyota.co.th.asia",
  countryCd: "TH",
  companyLoginId: "009",
};

// Example
// {
//   "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#users/$entity",
//   "businessPhones": [],
//   "displayName": "Panisara Saelee (TMA)",
//   "givenName": "Panisara",
//   "jobTitle": "OUTSOURCE",
//   "mail": "panidara_dae_fth@tdem.toyota-asia.com",
//   "mobilePhone": null,
//   "officeLocation": "Bangbo",
//   "preferredLanguage": "en-US",
//   "surname": "Saelee",
//   "userPrincipalName": "panidara_dae_fth_tdem.toyota-asia.com#EXT#@onetoyota.onmicrosoft.com",
//   "id": "7d5b9ba2-df87-4e89-9000-3ce6b2313b17"
// }
