import React, { useEffect } from "react";
import { Box, Dialog, DialogContent, DialogContentText, Grid } from "@mui/material";
import { AlertMessage, InputButton, InputEditTableCurrency, InputEditTableDropDown } from "components/UI";

import "./ModalPrice.css";
import { DataGrid, GridRowModes, GridRowEditStopReasons } from "@mui/x-data-grid";
import { useGetCurrency } from "service/rpackagetype";
import { useState } from "react";
import ConfirmDialog from "components/UI/ConfirmDialog";
import { message } from "utils/message";
import { formatCurrency, formatDate } from "utils";
import { ModeAction } from "state/enum";
import { userProfile } from "constant";
import { getLocalDate } from "utils/init-config-date";
import { HeaderContentBar } from "components/Layout";

export function ModalPrice({
  open = true,
  setOpen,
  rowNumber,
  initialData = [],
  setRowsCurrencys,
  rowsCurrencys,
  modeRows,
  functionId,
}) {
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [editButton, setEditButton] = useState(true);
  const [addButton, setAddButton] = useState(true);
  const [deleteButton, setDeleteButton] = useState(true);
  const [saveButton, setSaveButton] = useState(true);

  useEffect(() => {
    if (modeRows) {
      if (ModeAction.VIEW === modeRows) {
        setEditButton(true);
        setAddButton(true);
        setDeleteButton(true);
        setSaveButton(true);
      } else {
        setEditButton(true);
        setAddButton(false);
        setDeleteButton(true);
        setSaveButton(false);
      }
    }
  }, [open]);

  const [isConfirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isConfirmCloseModal, setConfirmCloseModal] = useState(false);

  const [msgError, setMsgError] = useState([]);

  const { data: currencyList } = useGetCurrency({
    dataOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    setRows([]);
    if (initialData.length) {
      const data = initialData.map((item, index) => ({
        seqNo: item.seqNo || index + 1,
        price: item.price,
        currentCd: item.currentCd,
        rowNumber: rowNumber,
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        isNew: false,
      }));
      setRows(data);
    }
  }, [initialData, rowNumber, open]);

  const handleClose = () => {
    setConfirmCloseModal(true);
  };

  const handleSubmit = () => {
    // TODO: Action Save
    const newCurrency = rows.map((item, i) => ({
      companyId: "",
      rPkgTypeId: "",
      seqNo: i + 1,
      price: item.price,
      currentCd: item.currentCd,
      value: "",
    }));

    setRowsCurrencys(prevRows => {
      const updatedRows = prevRows.map(row => {
        const itemRows = { ...row };
        if (row.rowNumber === rowNumber) {
          return {
            ...itemRows,
            currencys: newCurrency,
          };
        }
        return itemRows;
      });
      return updatedRows;
    });

    setOpen(false);
  };

  const handleRowSelectionModelChange = newRowSelectionModel => {
    console.log("handleRowSelectionModelChange");
    if (newRowSelectionModel.length) {
      // setAddButton(true);
      setEditButton(false);
      setDeleteButton(false);
    } else {
      // setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const processRowUpdate = newRow => {
    // validate
    setMsgError([]);

    if (newRow.isNew) {
      let stopProcess = false;
      if (rows.filter(v => v.rowNumber === rowNumber && !v.isNew).some(v => v.currentCd === newRow.currentCd)) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "duplicateCurrency" })]);
        stopProcess = true;
      }
      if (!newRow.price) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "empty", msg: "Price" })]);
        stopProcess = true;
      }
      if (parseFloat(newRow.price) === 0 || (newRow.currentCd && !parseFloat(newRow.price))) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "moreThan0", msg: "Price" })]);
        stopProcess = true;
      }
      if (parseFloat(newRow.price) > 9999.99) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "formatPrice" })]);
        stopProcess = true;
      }
      if (!newRow.currentCd) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "empty", msg: "Currency" })]);
        stopProcess = true;
      }
      if (stopProcess) return;
    } else {
      console.log(rows);
      console.log(newRow);
      let stopProcess = false;
      if (
        rows
          .filter(v => v.rowNumber === rowNumber && !v.isNew && v.seqNo !== newRow.seqNo)
          .some(v => v.currentCd === newRow.currentCd)
      ) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "duplicateCurrency" })]);
        stopProcess = true;
      }
      if (!newRow.price || (newRow.currentCd && !parseFloat(newRow.price))) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "empty", msg: "Price" })]);
        stopProcess = true;
      }
      if (parseFloat(newRow.price) > 9999.99) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "formatPrice" })]);
        stopProcess = true;
      }
      if (!newRow.currentCd) {
        setMsgError(oldMsg => [...oldMsg, message({ type: "empty", msg: "Currency" })]);
        stopProcess = true;
      }
      if (stopProcess) return;
    }

    setMode(ModeAction.VIEW);
    setAddButton(false);
    setEditButton(true);
    setDeleteButton(true);
    setRowSelectionModel([]);

    const updatedRow = { ...newRow, isNew: false };
    setRows(prevRows =>
      prevRows.map(row => {
        return row.seqNo === newRow.seqNo ? updatedRow : row;
      })
    );

    return updatedRow;
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleAddClick = () => {
    setMode(ModeAction.ADD);
    setAddButton(true);

    // TODO: unique id for DataGrid
    const field = {};
    columns.forEach(column => {
      field[column.field] = "";
    });

    setRows(oldRows => [
      ...oldRows,
      {
        ...field,
        rowNumber: rowNumber,
        seqNo: rows.length + 1,
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [rows.length + 1]: { mode: GridRowModes.Edit, fieldToFocus: "price" },
    }));
    setRowSelectionModel([rows.length + 1]);
  };

  const handleEditClick = () => {
    if (rowSelectionModel.length > 1) {
      // setMsgError();
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
      return;
    }

    setMode(ModeAction.EDIT);
    setDeleteButton(true);
    setEditButton(true);

    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit, fieldToFocus: "price" },
      };
    });

    rowSelect && setRowModesModel(tempRowModesModel);
  };

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleCancelClick = () => {
    setConfirmCancelOpen(true);
  };

  const handleCancelConfirm = () => {
    setMsgError([]);
    if (mode === ModeAction.ADD) {
      setRowModesModel({
        ...rowModesModel,
        [rows.length]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find(row => row.seqNo === rows.length);
      if (editedRow.isNew && editedRow.isNew) {
        setRows(rows.filter(row => row.seqNo !== rows.length));
      }
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View, ignoreModifications: true },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }

    setMode(ModeAction.VIEW);
    setAddButton(false);
    setEditButton(true);
    setDeleteButton(true);
    // const updatedRows = rows.filter((v) => v.rowNumber === rowNumber).slice(0, -1);
    // setRows(updatedRows);
    setRowSelectionModel([]);
  };

  const handleDeleteConfirm = () => {
    let newRows = rows;
    rowSelectionModel.map(val => {
      newRows = newRows.filter(row => row.seqNo !== val);
    });
    setRows(newRows);
  };

  const handleCloseConfirm = () => {
    setMode(ModeAction.VIEW);
    setRows([]);
    setMsgError([]);
    setEditButton(true);
    setOpen(false);

    setRowModesModel({
      ...rowModesModel,
      [rows.length]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    setAddButton(false);
    setEditButton(true);
    setDeleteButton(true);
    const updatedRows = rows.filter(v => v.rowNumber === rowNumber).slice(0, -1);
    setRows(updatedRows);
    setRowSelectionModel([]);
  };

  const handleSaveClick = () => {
    setConfirmSaveOpen(true);
  };

  const handleSaveConfirm = () => {
    if (mode === ModeAction.ADD) {
      setRowModesModel({
        ...rowModesModel,
        [rows.length]: { mode: GridRowModes.View },
      });
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }
  };

  const columns = [
    // { field: "id", hideable: true },
    {
      field: "seqNo",
      sortable: false,
      headerName: "No.",
      width: 150,
      editable: false,
      headerAlign: "center",
      align: "right",
      renderCell: params => {
        if (params.row.seqNo === rows.slice(-1)?.[0].seqNo && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
      renderEditCell: () => {},
    },
    {
      field: "price",
      sortable: false,
      headerName: "Price",
      type: "string",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "right",
      renderEditCell: params => {
        return (
          <InputEditTableCurrency {...params} required decimalScale={2} style={{ width: "250px" }} maxLimit={10000} />
        );
      },
      renderCell: params => {
        // console.log('params?.value', params?.value);
        return formatCurrency({ number: params?.value, digits: 2 });
        // return params?.value
      },
    },
    {
      field: "currentCd",
      sortable: false,
      headerName: "Currency",
      width: 300,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderEditCell: params => {
        return (
          <div style={{ margin: "0px 15px", width: "100%" }}>
            <InputEditTableDropDown
              required
              {...params}
              memu={currencyList?.map(val => ({
                key: val.cd,
                value: val.cd,
              }))}
              placeholder="<All>"
              isCurrency={true}
            />
          </div>
        );
      },
      renderCell: params => {
        const name =
          params.value &&
          currencyList?.find(val => {
            return val.cd === params.value;
          })?.cd;

        return <p>{name}</p>;
      },
      // preProcessEditCellProps: (params) => {
      //   if (!params.props.value) {
      //     setSaveButton(true);
      //   } else {
      //     setSaveButton(false);
      //   }
      //   return { ...params.props };
      // },
    },
  ];

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Box className="area-row-header">
              <div className="btn-close" onClick={handleClose}>
                Close
              </div>
              <div>User: UserPNG4</div>
            </Box>
            <Box className="area-row-header">
              <HeaderContentBar title="WDN91012 : R-Package Type Master Maintenance Price Sub Screen" />
              {/* <div>WDN91012 : R-Package Type Master Maintenance Price Sub Screen</div>
              <div>{getLocalDate().format("DD MMM YYYY HH:mm")}</div> */}
            </Box>
          </DialogContentText>
          {/* AlertMessage */}
          <AlertMessage type={"warning"} message={msgError} />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <p>R-Package Category:</p>
              <p>R-Package Type:</p>
              <p>R-Package Description:</p>
              <p>Effective Date (From)(DD/MM/YYYY):</p>
              <p>Effective Date (To)(DD/MM/YYYY):</p>
            </div>

            <div style={{ flex: 1, textAlign: "right", color: "grey" }}>
              <p style={{ height: 18 }}>{rowsCurrencys.find(v => v.rowNumber === rowNumber)?.value}</p>
              <p style={{ height: 18 }}>{rowsCurrencys.find(v => v.rowNumber === rowNumber)?.rPkgType}</p>
              <p style={{ height: 18 }}>{rowsCurrencys.find(v => v.rowNumber === rowNumber)?.rPkgDesc}</p>
              <p style={{ height: 18 }}>
                {formatDate(rowsCurrencys.find(v => v.rowNumber === rowNumber)?.effDtFrom, "DD/MM/YYYY")}
              </p>
              <p style={{ height: 18 }}>
                {formatDate(rowsCurrencys.find(v => v.rowNumber === rowNumber)?.effDtTo, "DD/MM/YYYY")}
              </p>
            </div>
          </Box>
          <Box className="box">
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <InputButton value={"Add"} size="small" onClick={handleAddClick} disabled={addButton} name={`${functionId}Add`} />
                <InputButton value={"Edit"} size="small" onClick={handleEditClick} disabled={editButton} name={`${functionId}Edit`} />
                <InputButton value={"Delete"} size="small" onClick={handleDeleteClick} disabled={deleteButton} name={`${functionId}Delete`} />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 220,
                    width: "100%",
                    margin: "0 auto",
                    display: "table-caption",
                  }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row.Mui-selected": {
                        background: "none",
                      },
                      "& .MuiDataGrid-row.Mui-selected:hover": {
                        background: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor": {
                        border: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "wrap",
                        lineHeight: "20px",
                        textAlign: "center",
                      },
                      "& .MuiDataGrid-cell.MuiDataGrid-withBorderColor": {
                        border: "0.5px solid #cecece",
                        outline: "none",
                      },
                      "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                        outline: "none",
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        borderLeft: "1px solid #ffff",
                        borderTop: "none",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        "& .MuiDataGrid-withBorderColor:focus": {
                          outline: "none",
                        },
                        background: "#7F7F7F",
                        color: "#ffff",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        minHeight: "300px",
                      },
                      "& .Mui-odd": {
                        background: "#e3e3e3",
                      },
                      "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-withBorderColor": {
                        background: "none",
                      },
                      "& .MuiDataGrid-cell--editing.MuiDataGrid-cell.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor":
                        {
                          background: "none",
                        },
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none",
                      },
                    }}
                    getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
                    checkboxSelection
                    isRowSelectable={params => {
                      // ![ModeAction.ADD, ModeAction.EDIT].includes(mode)
                      if (ModeAction.VIEW === modeRows) {
                        return false;
                      } else {
                        if (ModeAction.VIEW === mode) {
                          if (rowSelectionModel.length) {
                            return params.row.seqNo != rowSelectionModel[0] ? false : true;
                          } else {
                            return true;
                          }
                        } else if (ModeAction.ADD === mode) {
                          return false;
                        } else {
                          return false;
                        }
                      }
                    }}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                    rowSelectionModel={rowSelectionModel}
                    getRowId={row => row.seqNo}
                    experimentalFeatures={{ columnGrouping: true }}
                    editMode="row"
                    rows={rows}
                    rowModesModel={rowModesModel}
                    columns={columns}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onCellDoubleClick={(params, event) => {
                      event.stopPropagation();
                    }}
                    onProcessRowUpdateError={error => {
                      console.log("onProcessRowUpdateError", error);
                    }}
                    hideFooter
                    disableColumnMenu
                  />
                </div>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {[ModeAction.ADD, ModeAction.EDIT].includes(mode) ? (
                  <>
                    <InputButton value={"Save"} onClick={handleSaveClick} size="small" disabled={saveButton} name={`${functionId}Save`}/>
                    <InputButton value={"Cancel"} onClick={handleCancelClick} size="small" name={`${functionId}Cancel`}/>
                  </>
                ) : (
                  <>
                    {ModeAction.VIEW !== modeRows && (
                      <InputButton onClick={handleSubmit} color={"primary"} value={"Confirm"} size="small" name={`${functionId}Confirm`}/>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              background: "#EB0A1E",
              textAlign: "center",
              color: "#fff",
              padding: "4px 0",
              marginTop: "24px",
            }}>
            &copy; 2024 -TOYOTA MOTOR ASIA (THAILAND) CO., LTD. All Rights Reserved
          </Box>
        </DialogContent>
      </Dialog>

      {/* ConfirmDialog Save */}
      <ConfirmDialog
        open={isConfirmSaveOpen}
        onClose={() => setConfirmSaveOpen(false)}
        onConfirm={() => {
          setConfirmSaveOpen(false);
          handleSaveConfirm();
        }}
        message={message({ type: "confirmSave" })}
        functionId={functionId}
        type={"Save"}
      />

      {/* ConfirmDialog Cancel */}
      <ConfirmDialog
        open={isConfirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        onConfirm={() => {
          setConfirmCancelOpen(false);
          handleCancelConfirm();
        }}
        message={message({ type: "confirmCancel" })}
        functionId={functionId}
        type={"Cancel"}
      />

      {/* ConfirmDialog Delete */}
      <ConfirmDialog
        open={isConfirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        onConfirm={() => {
          setConfirmDeleteOpen(false);
          handleDeleteConfirm();
        }}
        message={message({ type: "confirmDelete" })}
        functionId={functionId}
        type={"Delete"}
      />

      {/* ConfirmDialog Close Modal */}
      <ConfirmDialog
        open={isConfirmCloseModal}
        onClose={() => setConfirmCloseModal(false)}
        onConfirm={() => {
          setConfirmCloseModal(false);
          handleCloseConfirm();
        }}
        message={message({ type: "closeScreen" })}
        noRoleRequired={true}
      />
    </React.Fragment>
  );
}
