import React, { Fragment, useState, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { userProfile } from "constant";
import { useGetDownloadAndUploadMaster } from "service/downloadAndUpload";
import DownloadMaster from "./components/DownloadMaster";
import UploadMaster from "./components/UploadMaster";

const DownloadAndUploadMasterScreen = () => {
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    // upload
    upload: "",
    dataTableSearch: "",
    // download
    download: "",
    year: "",
    companyId: "",
    transportCd: "",
    transportMd: "",
    senderCompId: "",
    receiverCompId: "",
    effMonthFrom: "",
    effMonthTo: "",
    expCompId: "",
    impCompId: "",
    effDtFrom: "",
    effDtTo: "",
    rPkgTypeId: "",
    carFamilyTypeCd: "",
    carFamilyCd: "",
    subSeries: "",
    locationTypeCd: "",
    locationTypeDesc: "",
    rPkgOwnerCompAbbr: "",
    locationGroupId: "",
  });

  // api
  const { data: downloadAndUploadMasterData } = useGetDownloadAndUploadMaster({
    dataOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Tab") {
        const { upload, download } = searchForm;

        if (upload === "" && download === "") {
          const activeElement = document.activeElement;
          const uploadElement = document.getElementById("select_upload");
          const downloadElement = document.getElementById("select_download");

          if (activeElement === uploadElement) {
            event.preventDefault();
            downloadElement.focus();
          }
          if (activeElement === downloadElement) {
            event.preventDefault();
            uploadElement.focus();
          }
        }

        if(upload !== "" && download === "") {
          const activeElement = document.activeElement;
          const uploadElement = document.getElementById("select_upload");
          const downloadElement = document.getElementById("select_download");

          if(activeElement === downloadElement){
            event.preventDefault();
            uploadElement.focus();
          }
        }

        if(upload === "" && download !== "") {
          const activeElement = document.activeElement;
          const uploadElement = document.getElementById("select_upload");
          const downloadClearButton = document.getElementById("download_clear_btn");

          if(activeElement === downloadClearButton){
            event.preventDefault();
            uploadElement.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchForm]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN910B0 : Download and Upload Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Box>
          <UploadMaster
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            downloadAndUploadMasterData={downloadAndUploadMasterData}
            setMsgAlert={setMsgAlert}
            setMsgError={setMsgError}
          />
        </Box>

        <Divider sx={{ my: 4 }} />
        <Box>
          <DownloadMaster
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            downloadAndUploadMasterData={downloadAndUploadMasterData}
            setMsgAlert={setMsgAlert}
            setMsgError={setMsgError}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default DownloadAndUploadMasterScreen;
