import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { InputButton } from ".";

const ConfirmDialog = ({ open, onClose, onConfirm, title = "", message, functionId, type, noRoleRequired }) => {
  return (
    <Dialog id="confirm-dialog" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <InputButton
          id="button-confirm-cancel"
          onClick={onClose}
          color="secondary"
          variant="contained"
          value={"Cancel"}
          name={`${functionId}${type}`}
          noRoleRequired={noRoleRequired}
        />
        <InputButton id="button-confirm-ok" onClick={onConfirm} color="primary" variant="contained" value={"OK"} name={`${functionId}${type}`} noRoleRequired={noRoleRequired} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
