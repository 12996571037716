// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cancelVanningSlice = createSlice({
  name: 'cancelVanning',
  initialState: {
    table: [],
  },
  reducers: {
    setTable: (state, { payload }) => {
      state.table = payload
    }
  },
});

export const {  
  setTable
 } = cancelVanningSlice.actions;

export default cancelVanningSlice.reducer;
