import { Box, Grid } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputEditTableCurrency, InputEditTableText } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import DataTable from "pages/DataTable";
import ActionBar from "pages/Partials/ActionBar";
import SubmitBar from "pages/Partials/SubmitBar";
import React, { Fragment, useMemo, useState } from "react";
import {
  useCreateVolumeDimension,
  useDeleteVolumeDimension,
  useSearchVolumeDimension,
  useUpdateVolumeDimension,
  useVolumeContainerDownloadExcelMutation,
} from "service/volumeDimension";
import { MessageType, ModeAction } from "state/enum";
import { formatCurrency, message, messageTypeDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { columEditTable } from "utils/columEditTable";
import { getLocalDate } from "utils/init-config-date";
import { validationRequestErrors } from "utils/validation";

const ContainerTable = ({
  searchForm,
  setSearchForm,
  setMsgError,
  setMsgAlert,
  setOnSearch,
  onSearch,
  mode,
  setMode,
  refetchContainerTypeData,
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  const downloadExcel = useVolumeContainerDownloadExcelMutation();
  const { mutateAsync: searchDataAsync } = useSearchVolumeDimension({});
  const { mutateAsync: createDataAsync } = useCreateVolumeDimension({
    type: "container",
  });
  const { mutateAsync: editDataAsync } = useUpdateVolumeDimension({
    type: "container",
    VolumeDimensionId: rows.find(val => val.rowNumber === rowSelectionModel[0])?.containerTypeId,
  });
  const { mutateAsync: deleteDataAsync } = useDeleteVolumeDimension({
    type: "container",
    VolumeDimensionId: rows.find(val => val.rowNumber === rowSelectionModel[0])?.containerTypeId,
  });

  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        width: 50,
        editable: false,
        renderHeader: () => <div></div>,
        renderCell: params => {
          if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
          // return (pageNumber - 1) * 10 + params.value;
        },
      },
      {
        field: "containerType",
        editable: ModeAction.ADD === mode,
        align: "center",
        headerAlign: "center",
        headerName: "",
        sortable: false,
        minWidth: 300,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Container Type"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableText {...params} regularExp={/^[a-zA-Z0-9]{0,10}$/} required />;
        },
        renderCell: params => {
          return params.value;
        },
        flex: 2
      },
      // columEditTable({
      //   field: "containerType",
      //   editable: ModeAction.ADD === mode,
      //   width: 300,
      //   type: "text",
      //   required: true,
      //   rule: [
      //     {
      //       type: MessageType.EMPTY,
      //       key: ["Container Type"],
      //     },
      //   ],
      //   // regularExp: /^[a-zA-Z0-9]{0,10}$/,
      // }),
      {
        field: "lengthRatio",
        align: "right",
        headerAlign: "center",
        headerName: "Length",
        sortable: false,
        minWidth: 200,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Length", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Length"],
          },
        ],
        renderEditCell: params => {
          let lengthRatio = params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio = params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio = params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return <InputEditTableCurrency {...params} required maxLimit={1000} />;
        },
        renderCell: params => {
          return formatCurrency({ number: params.value });
        },
        flex: 1
      },
      {
        field: "widthRatio",
        align: "right",
        headerName: "Width",
        headerAlign: "center",
        sortable: false,
        minWidth: 200,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Width", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Width"],
          },
        ],
        renderEditCell: params => {
          let lengthRatio = params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio = params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio = params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return <InputEditTableCurrency {...params} required maxLimit={1000} />;
        },
        renderCell: params => {
          return formatCurrency({ number: params.value });
        },
        flex: 1
      },
      {
        field: "heightRatio",
        align: "right",
        headerName: "Height",
        headerAlign: "center",
        sortable: false,
        minWidth: 200,
        editable: true,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Height", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Height"],
          },
        ],
        renderEditCell: params => {
          let lengthRatio = params.row.lengthRatio !== "" ? params.row.lengthRatio : 0;
          let widthRatio = params.row.widthRatio !== "" ? params.row.widthRatio : 0;
          let heightRatio = params.row.heightRatio !== "" ? params.row.heightRatio : 0;

          let sumTotal = lengthRatio * widthRatio * heightRatio;

          // console.log(sumTotal);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "totalRatio",
            value: sumTotal,
          });

          return <InputEditTableCurrency {...params} required maxLimit={1000} />;
        },
        renderCell: params => {
          return formatCurrency({ number: params.value });
        },
        flex: 1
      },
      columEditTable({
        field: "totalRatio",
        width: 250,
        minWidth: 250,
        editable: true,
        type: "number",
        align: "right",
        maxLimit: 1000000,
        disabled: true,
        renderCell: params => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 2
      }),
      columEditTable({
        field: "acceptCloseWidth",
        width: 350,
        minWidth: 350,
        editable: true,
        type: "number",
        align: "right",
        required: true,
        maxLimit: 10000,
        rule: [
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Width Accept to Close", "0"],
          },
          {
            type: MessageType.EMPTY,
            key: ["Width Accept to Close"],
          },
        ],
        renderCell: params => {
          return formatCurrency({ number: params.value, digits: 0 });
        },
        flex: 2
      }),
    ],
    [rows]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Container Type",
      headerAlign: "center",
      children: [{ field: "containerType" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Container Ratio",
      headerAlign: "center",
      children: [{ field: "lengthRatio" }, { field: "widthRatio" }, { field: "heightRatio" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Total Ratio (LxWxH)",
      headerAlign: "center",
      children: [{ field: "totalRatio" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Width Accept to Close",
      headerAlign: "center",
      children: [{ field: "acceptCloseWidth" }],
      headerClassName: "align-items-end",
    },
  ];

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);

      // validate
      // if (!searchForm.rPkgTypeId) {
      //   setMsgError((oldMsg) => [
      //     ...oldMsg,
      //     message({ type: "empty", msg: "R-Package Type" }),
      //   ]);
      //   return;
      // }

      const { searchData, data } = await getSearch();

      // data not found
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm(prev => ({
      ...prev,
      dataOwner: "TMATH",
      dimensionType: "C",
      refCompanyAbbr: "",
      refImpExpCd: "",
      rPkgType: "",
      containerType: "",
    }));
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      const option = {
        dataOwner: userProfile.packageOwner,
        ...searchForm,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // const filename = `Volumn_Dimension_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      await downloadExcel(option);

      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      await deleteDataAsync({
        updateBy: userProfile.userId, // TODO: get dataOwner from profile
      });

      // const { searchData, data } = await getSearch(pageNumber);

      // setPagination(searchData?.result?.pagination ?? {});
      // setPageNumber(searchData?.result?.pagination?.pageNumber);
      // setRows(data);

      // setRowSelectionModel([]);
      // setMode(ModeAction.VIEW);

      const { searchData, data } = await getSearch();
      if (data.length === 0) {
        handleClear();
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        setRows(data);
      }

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      // Refetch Dropdown
      refetchContainerTypeData();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);

    // validate
    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    console.log("-- errors ->", errors);
    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      // to do save
      try {
        await createDataAsync({
          dataOwner: userProfile.packageOwner,
          containerType: newRow.containerType,
          lengthRatio: newRow.lengthRatio !== "" ? parseInt(newRow.lengthRatio) : "",
          widthRatio: newRow.widthRatio !== "" ? parseInt(newRow.widthRatio) : "",
          heightRatio: newRow.heightRatio !== "" ? parseInt(newRow.heightRatio) : "",
          acceptCloseWidth: newRow.acceptCloseWidth !== "" ? parseInt(newRow.acceptCloseWidth) : "",
          createBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchContainerTypeData();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scroll(0, 0);
        return;
      }
    } else {
      // to do edit
      try {
        await editDataAsync({
          dataOwner: userProfile.packageOwner,
          ...newRow,
          updateBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scroll(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <Fragment>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          firstField={"select_dimensionType"}
          functionId={"WDN91060"}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid container spacing={2}>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={columnGroupingModel}
                columnVisibilityModel={{
                  currencys: false,
                  rPkgTypeId: false,
                }}
                columnHeaderHeight={40}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91060"}
            />
          </Grid>
        </Box>
      </>
    </Fragment>
  );
};

export default ContainerTable;
