import React, { useEffect } from "react";
// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { findObject, messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { transformSubScreenData } from "../../../hooks/tranformData";
//Service
import { useExportInvoiceSearchSubScreenMutation } from "shared/services/invoice";
//Type
import { DEFAULT_STATE_WDN95011 } from "../../../constants/constants";
import { BUTTON_VALUE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { userProfile } from "constant";
export default function ButtonCriterial(props) {
  const {
    // mode,
    setMode,
    form,
    setForm,
    // onSearch,
    // rows,
    setRows,
    setOnSearch,
    // setShippingInfo,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { searchBtn, clearBtn },
    rowSelection: { setRowModesModel, setRowSelectionModel },
    pagination: { pagination, setPagination, setPageNumber, pageNumber },
    dataList: {
      // invoiceOrderTypeData,
      // ImpCompanyData,
      expData,
      // parkingMonthData,
      // vanningPlantData,
      // brokerData,
      importerData,
    },
    onOpen,
    onProcess,
    functionId,
  } = props;
  const searchExportInfo = useExportInvoiceSearchSubScreenMutation();

  const handleSearch = async event => {
    try {
      event?.preventDefault();
      setMsgAlert([]);
      !onOpen.isEdit && setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      !onOpen.isEdit && setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});

      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        nonCommInvHId: form?.nonCommInvHId ?? "",
        invoiceNo: form?.invNo,
        invoiceOrderType: form?.invType,
        etd: form?.etdDt !== "" && form.etdDt !== null && form.etdDt !== undefined ? form?.etdDt : undefined,
        vesselName: form?.vesselName,
        importerId: form?.impCompanyId,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        exporterId: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterId",
            }),
        exporterCompanyAbbr: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        exporterCd: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterCd",
            }),
        parkingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userProfile.comAbbrApmc, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };

      const searchData = await searchExportInfo(body);
      if (!searchData?.result?.data?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      const data = transformSubScreenData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      !(onOpen.isEdit && onProcess) && setMsgError(errors);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm({
      invNo: "",
      invType: "",
      etdDt: "",
      vesselName: "",
      impCompanyId: "",
      expCd: "",
      parkingMonth: "",
      vanningPlant: "",
      expBrokerCompanyId: "",

      // expCustomStockDt: "",
      expCustomsStkDt: "",
      nonComInvFlag: true,
      broker: "",
      currencyCd: "",
      expEntryNo: "",
      shipmentCd: "",
      shipCarierCompanyAbbr: "",
    });
    //clear local storage
    // setShippingInfo(DEFAULT_LOCAL_STATE_WDN95030);
    // Reset Data of Table
    setPageNumber(FIRST_PAGE);
    setRows([]);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    //Reset state
    setOnSearch(false);
    setMode(ModeAction.VIEW);
  };

  useEffect(() => {
    if (onOpen.isEdit && onProcess) {
      handleSearch();
    }
  }, [onOpen, onProcess]);

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <InputButton
          id="button-search"
          value={BUTTON_VALUE.SEARCH}
          disabled={searchBtn}
          onClick={handleSearch}
          name={`${functionId}Search`}
        />
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={clearBtn}
          onClick={handleClear}
          name={`${functionId}Clear`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
