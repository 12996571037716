import React, { Fragment, useState, useMemo } from "react";
// Components
import { Box, Stack } from "@mui/material";
import { FilterBar, ButtonBar, TableBar } from "./components";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
// Utils
import { responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { isEmpty, isNull } from "lodash";
// Hook
import useColumn from "./hooks/useColumn";
import useLocalStorage from "shared/hooks/useLocalStorage";
// Service
import {
  useOnDemandBatchBatchIdQuery,
  useOnDemandBatchProjectCodeQuery,
  useOnDemandBatchSearchMutation,
} from "shared/services/common/monitoring";
//Constants
import { ModeAction, MessageType } from "state/enum";
import { API_ON_DEMAND_BATCH, DEFAULT_LOCAL_STATE } from "shared/constants";
import { DEFAULT_ON_DEMAND_BATCH } from "./constants/onDemandBatch.constant";
export default function OnDemandBatchScreen() {
  const functionId = "WDN98170";

  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);

  // Form
  const [form, setForm] = useState(DEFAULT_ON_DEMAND_BATCH);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [appId, setAppId] = useState(null);
  // Table
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // API
  const { data: projectList } = useOnDemandBatchProjectCodeQuery();
  const { data: batchList } = useOnDemandBatchBatchIdQuery(isEmpty(form?.projectCode) ? "all" : form?.projectCode);
  const searchOnDemandBatch = useOnDemandBatchSearchMutation();
  const handleLinkBatchId = async (value, event) => {
    try {
      event.preventDefault();
      const monthlyState = {
        dataOwner: form?.dataOwner,
        moduleId: value?.projectCode,
        functionId: value?.batchId,
        statusId: "",
        userId: value?.createBy,
        appId: value?.aplId,
        dateFrom: value?.uploadDt,
        dateTo: value?.uploadDt,
        levelId: "",
      };
      setLogDetail(monthlyState);
      window.open("/common/monitoring/log-monitoring-detail", "_blank");
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const columns = useMemo(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useColumn({ rows, handleLinkBatchId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows]
  );
  // get search
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_ON_DEMAND_BATCH.PROJECT_CODE,
            type: MessageType.EMPTY,
            key: ["Project Code"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await searchOnDemandBatch(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_ON_DEMAND_BATCH.ROW_NUMBER]: item?.rowNumber,
          [API_ON_DEMAND_BATCH.PROJECT_CODE]: item?.projectCode,
          [API_ON_DEMAND_BATCH.BATCH_ID]: item?.batchId,
          [API_ON_DEMAND_BATCH.BATCH_NAME]: item?.batchname,
          [API_ON_DEMAND_BATCH.RUNNING_COUNT]: item?.runningCount,
          [API_ON_DEMAND_BATCH.RUN_AS]: item?.runAs,
          [API_ON_DEMAND_BATCH.END_POINT]: item?.endPoint,
          [API_ON_DEMAND_BATCH.SUPPORT_ID]: item?.supportId,
          [API_ON_DEMAND_BATCH.SCHEMA]: item?.schema,
          [API_ON_DEMAND_BATCH.CREATE_BY]: item?.createBy,
          [API_ON_DEMAND_BATCH.CREATE_DATE]: item?.createDate,
          [API_ON_DEMAND_BATCH.UPDATE_BY]: item?.updateBy,
          [API_ON_DEMAND_BATCH.UPDATE_DATE]: item?.updateDate,
        })) ?? [];

      return { searchData, data };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Fragment>
      <Stack direction="column" sx={{ padding: "1rem 1rem 0rem 1rem", mb: 2 }}>
        <HeaderContentBar title="WDN98170 : On Demand Batch Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Stack>
      <div id="appid" style={{ display: "none" }}>
        {appId ?? "no appId"}
      </div>
      <Stack spacing={2} direction="column">
        <Box sx={{ padding: "1rem" }}>
          <FilterBar
            form={form}
            setForm={setForm}
            setRows={setRows}
            setMode={setMode}
            setOnSearch={setOnSearch}
            setRowModesModel={setRowModesModel}
            setRowSelectionModel={setRowSelectionModel}
            setPageNumber={setPageNumber}
            setPagination={setPagination}
            dataList={{ projectList, batchList }}
          />
        </Box>
        <Box sx={{ padding: "1rem" }}>
          <ButtonBar
            mode={mode}
            setMode={setMode}
            form={form}
            setForm={setForm}
            rows={rows}
            setRows={setRows}
            onSearch={onSearch}
            setOnSearch={setOnSearch}
            setMsg={{ setMsgError: setMsgError, setMsgAlert: setMsgAlert }}
            setAppId={setAppId}
            pagination={{
              pagination: pagination,
              setPagination: setPagination,
              pageNumber: pageNumber,
              setPageNumber: setPageNumber,
            }}
            rowSelection={{
              rowModesModel: rowModesModel,
              setRowModesModel: setRowModesModel,
              rowSelectionModel: rowSelectionModel,
              setRowSelectionModel: setRowSelectionModel,
            }}
            functionId={functionId}
          />
        </Box>
        {onSearch && (
          <>
            <Box sx={{ padding: "1rem" }}>
              <TableBar
                form={form}
                mode={mode}
                rows={rows}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                setRows={setRows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                pagination={pagination}
                setPagination={setPagination}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setMsg={{ setMsgError, setMsgAlert }}
                getSearch={getSearch}
              />
            </Box>
          </>
        )}
      </Stack>
    </Fragment>
  );
}
