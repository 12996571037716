import { getLocalDate } from "utils/init-config-date";

export const formatRow = dataList => {
  try {
    const delivery = dataList?.result?.delivery;
    const total = dataList?.result?.total;
    const printed = dataList?.result?.printed;
    const accumulatePrinted = dataList?.result?.accumulatePrinted;
    const accumulateRemaining = dataList?.result?.accumulateRemaining;
    const warehouseSupplierList = ["", "Total(Sum)", "Printed", "Accumulate Printed", "Accumulate Remaining"];

    let data = [];
    let deliveryDate = [];
    let deliveryPlanDt = [];

    // Loop 5 times for each index (for total, printed, accumulatePrinted, etc.)
    for (let index = 0; index < 5; index++) {
      let tempRow = {}; // Reset tempRow for each row
      let row;

      delivery.forEach((val, indx) => {
        if (indx === 0 && index === 0) {
          val.dayQty.forEach(item => {
            if (!deliveryPlanDt.includes(item.key)) {
              deliveryPlanDt.push(item.key);

              item.data.forEach(val => {
                tempRow[val.day] = val.value;

                if (!deliveryDate.some(date => date === `${item.key}-${val.day}`)) {
                  deliveryDate.push(`${item.key}-${val.day}`);
                }
              });
            }
          });
          row = createRow(index, `${val?.whImpExpCd}(${val?.whName})`, tempRow);
        }
      });

      if (index === 1) {
        total.forEach(item => {
          item.data.forEach(dataItem => {
            tempRow[dataItem.day] = dataItem.value;
          });
        });
        row = createRow(index, warehouseSupplierList[index], tempRow);
      } else if (index === 2) {
        printed.forEach(item => {
          item.data.forEach(dataItem => {
            tempRow[dataItem.day] = dataItem.value;
          });
        });
        row = createRow(index, warehouseSupplierList[index], tempRow);
      } else if (index === 3) {
        accumulatePrinted.forEach(item => {
          item.data.forEach(dataItem => {
            tempRow[dataItem.day] = dataItem.value;
          });
        });
        row = createRow(index, warehouseSupplierList[index], tempRow);
      } else if (index === 4) {
        accumulateRemaining.forEach(item => {
          item.data.forEach(dataItem => {
            tempRow[dataItem.day] = dataItem.value;
          });
        });
        row = createRow(index, warehouseSupplierList[index], tempRow);
      }

      data.push(row); // Push each row into data array
    }

    return { data, deliveryDate, deliveryPlanDt };
  } catch (error) {
    console.error(error);
  }
};

function createRow(index, rowHeader, data) {
  return { no: index + 1, rowNumber: index + 1, warehouseSupplier: rowHeader, ...data };
}
