import React, { useCallback } from "react";
// Component
import { DataGridTable } from "components/UI";
import ButtonSubmit from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/ButtonSubmit";
//Util
import { messageDisplay, responseErrors } from "utils";
import { isEmpty } from "lodash";
import { transformDataRPkg } from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
import { validationSearchForm } from "utils/validation";
//Service
import { useRPackageSearchMutation } from "shared/services/invoice";
//Type
import { PAGINATION } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
export default function TableRPkg(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    setOnSearch,
    updateTempRPkg,
    loading: { startLoading, stopLoading },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    functionId,
  } = props;

  const searchRPkg = [useRPackageSearchMutation()];
  const handleChangePagination = async (event, value) => {
    try {
      const ROW_PER_PAGE_TWENTY = 20;
      setMsgAlert([]);
      setMsgError([]);
      setPageNumber(value);
      const body = {
        shippingInfoDContId: form?.shippingInfoDContId,
        vanningInfoHId: form?.vanningInfoHId, // optional
        containerNo: form?.containerNo,
        containerType: form?.containerType, // P = Plan, A = Actual
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
      };

      const searchData = searchRPkg(body);
      const data = transformDataRPkg(searchData?.result?.data);

      // Add r-package to container state
      let containerList;
      containerList = form?.rows?.map(cont => {
        let contd = form?.containerType === "P" ? cont?.planContainerNo : cont?.actualContainerNo;
        if (contd === form?.containerNo) {
          let temp = { ...cont, rPackage: data };
          return temp;
        }
        return cont;
      });
      setForm(prev => ({ ...prev, rows: containerList }));

      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleCreate = async newRow => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let msg;
      const { isSuccess, errors } = validationSearchForm({
        data: newRow,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
          {
            field: "rPkgType",
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
          {
            field: "planQty",
            type: MessageType.EMPTY,
            key: ["Plan Qty"],
          },
          {
            field: "planQty",
            type: MessageType.EQUAL_GREATER_ZERO,
            key: ["Plan Qty", "0"],
          },
        ],
      });
      setMsgError(errors);

      // check duplicate rPkgType (Check with id)
      let stopProcess = true;
      if (!isEmpty(newRow?.rPkgType) || !rows?.length) {
        const dupContainer = rows.find(row => row?.rPkgType === newRow?.rPkgType);
        if (dupContainer) {
          msg = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0039AERR",
            msg: [`R-Package Owner=${dupContainer?.rPkgOwner}, R-Package Type=${dupContainer?.rPkgType}`],
          });
          setMsgError(prev => [...prev, msg]);
          stopProcess = false;
        }
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }
      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90042AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const handleUpdate = async row => {
    try {
      let msg;

      const { isSuccess, errors } = validationSearchForm({
        data: row,
        rule: [
          {
            field: "planQty",
            type: MessageType.NUMERIC_EMPTY,
            key: ["Plan Qty"],
          },
          {
            field: "planQty",
            type: MessageType.EQUAL_GREATER_ZERO,
            key: ["Plan Qty", "0"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90043AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      return true;
    } catch (error) {
      console.error(error);
    }
  };
  const handleProcessRowUpdateError = useCallback(error => {
    console.error(` Error message from table:${error.message}`);
    // console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async newRow => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let updatedRow;
      let response;
      if (newRow.isNew) {
        response = await handleCreate(newRow);
        updatedRow = { ...newRow, isNew: false };
      } else {
        response = await handleUpdate(newRow);
        updatedRow = { ...newRow };
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;

      // Save update row to rows variable
      let tempRows;
      let isUpdateRow = false;
      let tempFormRows = rows;
      tempRows = rows?.map((row, index) => {
        if (row.rowNumber === updatedRow.rowNumber) {
          isUpdateRow = true;
          return { ...tempFormRows[index], ...updatedRow };
        }
        return row;
      });

      let temp = isUpdateRow ? tempRows : [...tempFormRows, updatedRow];
      setForm(prev => ({ ...prev, rPackage: temp }));
      setRows(temp);

      startLoading();
      const sessionId = await updateTempRPkg(temp, form?.contSessionId);
      console.log("container sessionId", sessionId);

      stopLoading();
      setForm(prev => ({ ...prev, [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: sessionId }));
      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);

      return updatedRow;
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const handleCancel = async rows => {
    try {
      if (mode === ModeAction.ADD) {
        await updateTempRPkg(rows, form?.contSessionId);
      } else if (mode === ModeAction.EDIT) {
        setRows(form?.rPackage);
        await updateTempRPkg(form?.rPackage, form?.contSessionId);
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <DataGridTable
        id="table-rPackage-sub"
        mode={mode}
        setMode={setMode}
        rows={rows}
        onCellClick={true}
        checkboxSelection={true}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        handleChangePagination={handleChangePagination}
        column={{
          columns,
          columnVisibilityModel: {
            rPkgOwner: false,
            rPkgType: false,
            shippingInfoDContId: false,
            shippingInfoDPkgId: false,
            groupCd: false,
          },
        }}
        rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
        pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
      />
      <ButtonSubmit
        mode={mode}
        setMode={setMode}
        rows={rows}
        setRows={setRows}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        setOnSearch={setOnSearch}
        isRPkg={true}
        executeFunction={handleCancel}
        functionId={functionId}
      />
    </React.Fragment>
  );
}
