import ConfirmDialog from "components/UI/ConfirmDialog";
import React, { useContext, useState } from "react";
import { message } from "utils";

const ConfirmDialogContext = React.createContext({
  //   notification: null,
  //   notificationText: message,
  // eslint-disable-next-line no-unused-vars
  success: ({ type = undefined, msg = "" }) => {},
  error: () => {},
});

// const STATES = {
//   SUCCESS: "success",
//   ERROR: "error",
// };

const ConfirmDialogProvider = (props) => {
  //   const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);
  const [isConfirmShow, SetisConfirmShow] = useState(false);
  const resolver = React.useRef();

  const success = ({ type = undefined, msg = "" }) => {
    // window.scroll(0, 0);
    setNotificationText(message({ type, msg }));
    SetisConfirmShow(true);
    // setNotification(STATES.SUCCESS);

    // console.log(message);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };
  const error = (text) => {
    // window.scroll(0, 0);
    setNotificationText(text);
    // setNotification(STATES.ERROR);
    SetisConfirmShow(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const clear = () => {
    resolver.current && resolver.current(false);

    SetisConfirmShow(() => false);
    // setNotificationText(null);
    // setNotification(null);
  };

  const confirm = async () => {
    resolver.current && resolver.current(true);

    SetisConfirmShow(() => false);
    // setNotificationText(() => null);
    // setNotification(null);
  };

  return (
    <ConfirmDialogContext.Provider
      value={{
        success,
        error,
        // notification,
        // notificationText,
      }}
    >
      {props.children}
      <ConfirmDialog
        open={isConfirmShow}
        onClose={clear}
        onConfirm={confirm}
        message={notificationText}
        noRoleRequired={true}
      />
    </ConfirmDialogContext.Provider>
  );
};

export { ConfirmDialogProvider };
export default ConfirmDialogContext;
export const useConfirmDialogContext = () => useContext(ConfirmDialogContext);
