import { Grid } from "@mui/material";
import PaginationTable from "./Pagination";
import CustomTanStackTable from "./CustomTable/CustonTanStackTable";
import { ModeAction } from "state/enum";
import { isNull } from "lodash";

export default function TanStackTable({
  id,
  mode,
  setMode,
  rows,
  getRowId,
  // column
  columns,
  columnVisibility,
  //Edit table
  processRowUpdate,
  tableHeight = "400px",
  //row selection
  rowSelectionKey,
  setRowSelectionKey,
  rowSelection,
  setRowSelect,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  handleRowDisableSelect = null,
  //row selection
  onPagination = false,
  pageNumber,
  pagination,
  handleChangePagination,
  isShipment,
  isrPkg,
  rowsChanged,
}) {
  const onRowSelectionChange = newRow => {
    try {
      const keys = Object.keys(rowSelectionKey);
      if (!keys.length) {
        setMode(ModeAction.SELECTED);
      } else {
        setMode(ModeAction.VIEW);
      }
      setRowSelectionKey(newRow);
    } catch (error) {
      console.error(error);
    }
  };
  const handleRowSelectable = row => {
    try {
      if (enableMultiRowSelection) {
        return true;
      }
      const keys = Object.keys(rowSelectionKey);
      // For on single select row mode
      if (mode === ModeAction.SELECTED) {
        return keys.includes(row.id) ? true : false;
      }
      if ([ModeAction.ADD, ModeAction.EDIT].includes(mode)) return false;

      if (!isNull(handleRowDisableSelect)) {
        // For disable checkBox selection by  field
        return handleRowDisableSelect(row);
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <>
      {rows?.length > 0 && (
        <CustomTanStackTable
          tableHeight={tableHeight}
          getRowId={getRowId}
          mode={mode}
          data={rows}
          columns={columns}
          columnVisibility={columnVisibility}
          processRowUpdate={processRowUpdate}
          enableRowSelection={enableRowSelection}
          enableMultiRowSelection={enableMultiRowSelection}
          //row selection
          rowSelectionKey={rowSelectionKey}
          rowSelection={rowSelection}
          setRowSelect={setRowSelect}
          handleRowSelectable={handleRowSelectable}
          onRowSelectionChange={onRowSelectionChange}
          handleRowDisableSelect={handleRowDisableSelect}
          isShipment={isShipment}
          isrPkg={isrPkg}
          rowsChanged={rowsChanged}
        />
      )}
      {onPagination && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
          <PaginationTable
            pagination={pagination}
            pageNumber={pageNumber}
            handleChangePagination={handleChangePagination}
            mode={mode}
          />
        </Grid>
      )}
    </>
  );
}
