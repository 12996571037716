import React, { Fragment } from "react";
// Utils
import { validationSearchForm } from "utils/validation.js";
import { messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
// Components
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Type
import { MessageType, MSG_TYPE, ModeAction } from "state/enum";
import { BUTTON_VALUE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { API_SYSTEM_MASTER } from "shared/constants";
import { userProfile } from "constant";
//API
import {
  useSystemMasterDeleteMutation,
  useSystemMasterDownloadTemplateMutation,
} from "shared/services/master-maintenance/system-master.service";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function ActionTableBar(props) {
  const {
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    onSearch,
    setOnSearch,
    getSearch,
    pagination: { pageNumber, setPageNumber, setPagination },
    stateBtn: { addBtn, editBtn, deleteBtn, downloadBtn },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowSelectionModel, rowModesModel, setRowSelectionModel, setRowModesModel },
    functionId,
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const deleteSystemMaster = useSystemMasterDeleteMutation();
  const downloadSystemMaster = useSystemMasterDownloadTemplateMutation();
  const handleAddBtn = async () => {
    let maxNo = -1;
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    const field = {};
    columns.forEach(column => {
      if (column.field === "rowNumber") {
        maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
        field[column.field] = rows.length ? maxNo + 1 : maxNo;
      } else {
        field[column.field] = "";
      }
    });
    setRows(prev => [
      ...prev,
      {
        ...field,
        isNew: true,
        status: "active",
        category: form?.category,
        subCategory: form?.subCategory,
      },
    ]);

    const maxNoModel = rows.length ? maxNo + 1 : maxNo;

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNoModel]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNoModel]);
  };
  const handleEditBtn = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1017AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      setMode(ModeAction.EDIT);
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.Edit },
        };
      });

      if (rowSelect) {
        setRowModesModel(tempRowModesModel);
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleDeleteBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1016AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const categoryId = row?.category;
      const deleteData = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner, // !MOCK
        [API_SYSTEM_MASTER.SUB_CATEGORY]: row?.subCategory,
        [API_SYSTEM_MASTER.CODE]: row?.code,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: { ...deleteData, categoryId },
        rule: [
          {
            field: "categoryId",
            type: MessageType.EMPTY,
            key: ["Category"],
          },
          {
            field: API_SYSTEM_MASTER.SUB_CATEGORY,
            type: MessageType.EMPTY,
            key: ["Sub Category"],
          },
          {
            field: API_SYSTEM_MASTER.CODE,
            type: MessageType.EMPTY,
            key: ["Code"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const response = await deleteSystemMaster(categoryId, deleteData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);

      const bodySearch = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
        [API_SYSTEM_MASTER.CATEGORY]: form?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: form?.subCategory,
        [API_SYSTEM_MASTER.CODE]: form?.code,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(bodySearch);
      stopLoading();

      if (!data.length) {
        setOnSearch(false);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        setPageNumber(FIRST_PAGE);

        setForm({
          userId: userProfile.userId,
          [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
          [API_SYSTEM_MASTER.CATEGORY]: "",
          [API_SYSTEM_MASTER.SUB_CATEGORY]: "",
          [API_SYSTEM_MASTER.CODE]: "",
          [API_SYSTEM_MASTER.VALUE]: "",
          [API_SYSTEM_MASTER.REMARK]: "",
          [API_SYSTEM_MASTER.STATUS]: "",
          [API_SYSTEM_MASTER.STATUS_ID]: "",
          [API_SYSTEM_MASTER.CREATE_BY]: "",
          [API_SYSTEM_MASTER.CREATE_DATE]: "",
          [API_SYSTEM_MASTER.UPDATE_BY]: "",
          [API_SYSTEM_MASTER.UPDATE_DATE]: "",
        });
        // setMsgError([messageDisplay({ type: "error", code: "MSTD0059AERR", msg: [""] })]);
        return;
      }
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleDownloadBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const body = {
        [API_SYSTEM_MASTER.DATA_OWNER]: form?.dataOwner,
        [API_SYSTEM_MASTER.CATEGORY]: form?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: form?.subCategory,
        [API_SYSTEM_MASTER.CODE]: form?.code,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: 5,
      };
      // const fileName = `TB_M_SYSTEM_${getLocalDate().format("YYYYMMDDHHmmss")}.xlsx`;
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_SYSTEM_MASTER.CATEGORY,
            type: MessageType.EMPTY,
            key: ["Category"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      await downloadSystemMaster(body);
      stopLoading();
      const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MSTD0081AINF", msg: [""] });
      setMsgAlert([msg]);
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <InputButton
            id="btn_add"
            value={BUTTON_VALUE.ADD}
            disabled={addBtn}
            onClick={handleAddBtn}
            onKeyDown={e => {
              if (!onSearch && e.key === "Tab") {
                e.preventDefault();
                document.getElementById("select_category").focus();
              }
            }}
            name={`${functionId}Add`}
          />
          {onSearch && (
            <>
              <InputButton id="btn_edit" value={BUTTON_VALUE.EDIT} disabled={editBtn} onClick={handleEditBtn} name={`${functionId}Edit`} />
              <InputButton id="btn_delete" value={BUTTON_VALUE.DELETE} disabled={deleteBtn} onClick={handleDeleteBtn} name={`${functionId}Delete`} />
              <InputButton
                id="btn_download"
                value={BUTTON_VALUE.DOWNLOAD}
                disabled={downloadBtn}
                onClick={handleDownloadBtn}
                name={`${functionId}Download`}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
