import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_DELIVERY_PLAN } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useGetRPackageMakerCompanyListQuery,
  useExcelDownloadOnlineMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";

export default function DeliveryPlanActualReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_DELIVERY_PLAN);

  const { data: rPkgMakerList, failureReason: rPkgMakerFailureReason } = useGetRPackageMakerCompanyListQuery();
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    let message = [];
    if (rPkgMakerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Maker List: ${ResponseText[rPkgMakerFailureReason?.response?.status]}`);
    }
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    rPkgMakerFailureReason?.response?.status,
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        dataOwner: userProfile.dataOwner,
        poNumber: form?.poNumber,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageMaker: form?.rPkgMaker,
        rPackageType: form?.rPkgType,
        monthFrom: form?.monthForm,
        monthTo: form.monthTo,
      };
      const pathReport = "generate-delivery-plan-actual-report";
      // const fileName = `Delivery_Plan_Actual_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response, props.setMsgError, stopLoading);
      // stopLoading();
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpkg-maker").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Delivery Plan Actual Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*R-Package Maker : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpkg-maker"
            fullWidth
            required
            value={form?.rPkgMaker}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgMakerList?.map((val, key) => ({
              key: val.name,
              value: val.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgMaker: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
                rPkgType: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2} textAlign="right">
          <strong>*R-Package Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form?.rPkgTypeOwner.length}
            required
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>Month (From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            views={["year", "month"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthForm}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthForm: e,
              }))
            }
          />
        </Grid>
        <Grid item xs={1}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={2} textAlign="right">
          <strong>Month (To) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            views={["year", "month"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthTo}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthTo: e,
              }))
            }
          />
        </Grid>
        <Grid item xs={1}>
          (MM/YYYY)
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>P/O Number : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-po-number"
            fullWidth={true}
            maxLength={15}
            value={form?.poNumber}
            regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                poNumber: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_DELIVERY_PLAN}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-rpkg-maker"}
      />
    </Grid>
  );
}
