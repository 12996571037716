import React, { Fragment, useCallback } from "react";
//utils
import { validationSearchForm } from "utils/validation";
import { findObject, responseErrors, responseSuccess } from "utils";
// Share Component
import { DataGridTable, SubmitBar } from "components/UI";
// Type
import {
  API_NAME_IMPORT_INVOICE_DOWNLOAD,
  FIRST_PAGE,
  PAGINATION,
  ROW_PER_PAGE,
} from "shared/constants";
import { ModeAction, MessageType } from "state/enum";
import { userProfile } from "constant";
import { GENERATE_STATUS, UPLOAD_STATUS } from "../constants/import.type.js";
import { useImportInvoiceDownloadUpdateMutation } from "shared/services/invoice/import-invoice-download-service.js";
import _ from "lodash";
export default function Table({
  form,
  onSearch,
  setOnSearch,
  mode,
  setMode,
  rows,
  setRows,
  columns,
  getSearch,
  dataList: { impExpList, dataTypeList, modeList },
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, pageNumber, setPagination, setPageNumber },
  rowSelection: {
    rowSelectionModel,
    setRowSelectionModel,
    rowModesModel,
    setRowModesModel,
  },
  rest,
}) {
  const importInvoiceEdit = useImportInvoiceDownloadUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);

      const bodySearch = {
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: form?.etdFrom,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: form?.etdTo,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: form?.etaFrom,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: form?.etaTo,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: form?.exporterId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_ID]: userProfile.userId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_LOCATION]: userProfile.dataOwner,

        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData } = await getSearch(bodySearch);
      // ? TRANSFORM DATA
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ROW_NUMBER]: item.rowNumber,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_COMPANY_ABBR]:
            item.expCompanyAbbr,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_NO]: item.invNo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_CD]: item.expCd,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CD]: item.impCd,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_DT]: item.invDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_DT]: item.etdDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_DT]: item.etaDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.VESSEL_NAME]: item.vesselName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_TYPE]: item.rPkgType,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_QTY]: item.rPkgQty,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT]:
            item.impCustomsStkDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ABBR]:
            item.impBrokerCompanyAbbr,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ID]:
            item.impBrokerCompanyId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_ENTRY_NO]: item.impEntryNo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ORG_BL_FILE_NAME]:
            item.orgBlFileName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.BL_FILE_NAME]: item.blFileName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.NON_COMM_INV_HID]:
            item.nonCommInvHId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_DT]: item.updateDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_BY]: item.updateBy,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_EDITABLE]: item.invEditable,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.TOTAL_M3_DISP_FLAG]:
            item.totalM3DispFlag,
        })) ?? [];

      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      console.log(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleUpdate = async (row) => {
    console.log(form);
    try {
      const body = {
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.NON_COMM_INV_HID]: row.nonCommInvHId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT]:
          !form.impCustomsStkDt?.length && !row.impCustomsStkDt?.length
            ? undefined
            : form.impCustomsStkDt ?? row.impCustomsStkDt,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ID]:
          row.impBrokerCompanyId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_ENTRY_NO]: row.impEntryNo,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_DT]: row.updateDt,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_BY]: row.updateBy,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_ID]: userProfile.userId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_LOCATION]: userProfile.dataOwner,
      };
      // const { isSuccess, errors } = validationSearchForm({
      //   data: body,
      //   rule: [
      //     {
      //       field: API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT,
      //       type: MessageType.EMPTY,
      //       key: ["Import Customs Stock Date"],
      //     },
      //   ],
      // });
      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return false;
      // }

      const response = await importInvoiceEdit(body);

      // const msg = response.message;
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleProcessRowUpdateError = useCallback((error) => {
    console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async (newRow) => {
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      response = await handleUpdate(newRow);
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setRows([]);
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);

        const bodySearch = {
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_OWNER]: userProfile.dataOwner,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: form?.etdFrom,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: form?.etdTo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: form?.etaFrom,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: form?.etaTo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: form?.exporterId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_ID]: userProfile.userId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_LOCATION]:
            userProfile.dataOwner,

          [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
          [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
        };
        const { searchData } = await getSearch(bodySearch);
        // ? TRANSFORM DATA
        const data =
          searchData?.result?.data?.map((item, index) => ({
            no: index + 1,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.ROW_NUMBER]: item.rowNumber,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_COMPANY_ABBR]:
              item.expCompanyAbbr,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_NO]: item.invNo,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_CD]: item.expCd,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CD]: item.impCd,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_DT]: item.invDt,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_DT]: item.etdDt,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_DT]: item.etaDt,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.VESSEL_NAME]: item.vesselName,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_TYPE]: item.rPkgType,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_QTY]: item.rPkgQty,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT]:
              item.impCustomsStkDt,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ABBR]:
              _.isEmpty(item.impBrokerCompanyAbbr)
                ? ""
                : item.impBrokerCompanyAbbr,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ID]: _.isEmpty(
              item.impBrokerCompanyId
            )
              ? ""
              : item.impBrokerCompanyId,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_ENTRY_NO]: item.impEntryNo,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.ORG_BL_FILE_NAME]:
              item.orgBlFileName,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.BL_FILE_NAME]: item.blFileName,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.NON_COMM_INV_HID]:
              item.nonCommInvHId,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_DT]: item.updateDt,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_BY]: item.updateBy,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_EDITABLE]: item.invEditable,
            [API_NAME_IMPORT_INVOICE_DOWNLOAD.TOTAL_M3_DISP_FLAG]:
              item.totalM3DispFlag,
          })) ?? [];
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      }
      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleRowDisableSelect = (params) => {
    if (params?.row.useSts === GENERATE_STATUS.GENERATED) return false;
    if (params?.row.uploadSts !== UPLOAD_STATUS.PROCESSING) {
      return true;
    }
  };
  return (
    <Fragment>
      {onSearch && (
        <DataGridTable
          mode={mode}
          onSearch={onSearch}
          setMode={setMode}
          rows={rows}
          isMultipleSelection={true}
          checkboxSelection={true}
          onCellClick={false}
          rowHeight={45}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          handleRowDisableSelect={handleRowDisableSelect}
          rowSelection={{
            rowModesModel: rowModesModel,
            setRowModesModel: setRowModesModel,
            rowSelectionModel: rowSelectionModel,
            setRowSelectionModel: setRowSelectionModel,
          }}
          pagination={{
            pagination: pagination,
            pageNumber: pageNumber,
            handleChangePagination: handleChangePagination,
          }}
          column={{
            columns: columns,
            columnGroupingModel: null,
          }}
        />
      )}
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        functionId={"WDN95020"}
      />
    </Fragment>
  );
}
